import React from 'react'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

const StyledProductFeatures = styled.div``

const Content = styled(ParseContent)`
  background-color: ${(props) => props.theme.color.face.light};
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 40px 50px 20px 50px;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;

  @media screen and (max-width: 991px) {
    padding: 25px 30px;
    bottom: -150px;

    & p {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 576px) {
    left: 0;
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
    bottom: -200px;
  }

  @media screen and (max-width: 400px) {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    bottom: -240px;
  }

  @media screen and (max-width: 350px) {
    bottom: -270px;
    padding: 25px 15px;
  }
`

const Wrapper = styled.div`
  max-width: 1680px;
  position: relative;

  & > div {
    height: 600px;

    @media screen and (max-width: 1200px) {
      height: 600px;
    }

    @media screen and (max-width: 991px) {
      height: 400px;
      margin-bottom: 100px;
    }

    @media screen and (max-width: 576px) {
      height: 260px;
      margin-bottom: 150px;
    }

    @media screen and (max-width: 400px) {
      height: 200px;
      margin-bottom: 200px;
    }

    @media screen and (max-width: 350px) {
      height: 150px;
      margin-bottom: 250px;
    }
  }

  @media screen and (min-width: 1440px) {
    margin-left: auto;
    margin-right: auto;
  }
`

const StyledImage = styled(Plaatjie)`
  position: absolute !important;
  width: 100%;
`

interface ProductFeaturesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_ProductFeatures
}

const ProductFeatures: React.FC<ProductFeaturesProps> = ({ fields }) => (
  <StyledProductFeatures>
    <Wrapper>
      <StyledImage image={fields?.image} alt="" loading="eager" />
      <div className="container position-relative">
        <Content content={fields.description} />
      </div>
    </Wrapper>
  </StyledProductFeatures>
)

export default ProductFeatures
